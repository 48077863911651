import React from "react";
import { Link } from "gatsby";

import { Layout } from "../layout/Layout";
import icon404 from "../assets/images/404-icon.svg";
import RightArrow from "../assets/icons/right-arrow-long-green.inline.svg";

const NotFoundPage = () => {
  return (
    <Layout pageId="404">
      <div className="h-96 p-10 flex items-end">
        <img src={icon404} alt="404 Icon" className="mx-auto" />
      </div>
      <div className="bg-[#F4F8F8] p-10 text-center">
        <h1 className="font-bold text-3xl md:text-7xl mb-6">Error 404</h1>
        <p className="text-xl mb-4 md:mb-12 font-light">No hemos encontrado la página que estás buscando</p>
        <Link className="my-3 inline-flex items-center gap-2 font-bold text-[#00AAA6] max-w-fit" to="/">
          Volver al inicio
          <RightArrow />
        </Link>
      </div>
    </Layout>
  );
};

export default NotFoundPage;
